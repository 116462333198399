@import '@Styles/laika-variables.module.sass'
@import '@Styles/utils.sass'

.container
  width: 100%
  background-color: $neutral-disabled-color
  min-height: 100vh
  overflow: hidden
  display: flex

.container *
  font-family: 'Onest', sans-serif
