@import '@Styles/laika-variables.module.sass'
@import '@Styles/utils.sass'

.button
  appearance: none
  outline: none
  border: 0
  border-radius: 0
  box-shadow: none
  &:hover,
  &:focus,
  &:active
    outline: none
    border: 0
    border-radius: 0
    box-shadow: none

.centerTitleIcon
  margin-bottom: 12px
  +breakpoint($small)
    margin-bottom: 12px
  svg
    width: 40px
    height: 40px
    +breakpoint($small)
      width: 48px
      height: 48px

.authTitle,
.signUpTitleH1
  margin: 0
  font-style: normal
  font-weight: 700
  font-size: 48px
  line-height: 56px
  +breakpoint($small)
    font-size: 32px
    line-height: 36px

.signUpTitleH2
  margin: 0
  font-style: normal
  font-weight: 700
  font-size: 36px
  line-height: 48px
  +breakpoint($small)
    font-size: 26px
    line-height: 32px
    &_signUpDoneUp
      font-size: 32px
      line-height: 36px

.authSubTitle
  font-weight: 500
  font-size: 16px
  line-height: 24px
  color: $neutral-dark-gray-color

.authInputGroup,
.signUpInputGroup
  margin-top: 30px
  display: flex
  flex-direction: column
  +breakpoint($small)
    margin-top: 16px

.integrationInputGroup
  margin-top: 16px

.authLabel,
.signUpLabel
  font-style: normal
  font-weight: 500
  font-size: 14px
  line-height: 18px
  margin-bottom: 6px

.inputBigCommonStyle
  font-style: normal
  font-weight: 400
  font-size: 18px
  line-height: 32px
  padding: 12px 16px
  background: #FFFFFF
  border: 1px solid $neutral-light-gray-color
  border-radius: 12px

.authInput
  composes: inputBigCommonStyle
  padding: 12px 16px
  background: #FFFFFF
  border: 1px solid $neutral-light-gray-color
  border-radius: 12px
  display: flex
  align-items: center
  outline: none !important
  width: 100%
  &:focus-visible
    border: 1px solid $brand-green-color
    box-shadow: 0px 0px 0px 4px $box-shadow-green-color
  &_isError
    border: 1px solid $border-status-danger-color
  &:disabled
    border: 1px solid transparent
    background: $neutral-disabled-color
  &_integrationModal
    padding: 8px 16px
  &_requestPlatformModal
    padding: 8px 16px
    font-size: 16px
    font-weight: 400
  &__errorMessage
    color: $border-status-danger-color
    font-weight: 400
    font-size: 14px
    line-height: 18px
    margin-top: 8px

.whiteBlockCommonStyle
  background: #FFFFFF
  border: 1px solid $border-neutral-light-gray-color
  box-shadow: 0px 6px 12px $box-shadow-gray-color
  border-radius: 16px
  &_padding4832
    padding: 48px 48px 32px
    +breakpoint($small)
      padding: 24px 24px 16px

.bigButton
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  min-height: 64px
  border-radius: 12px
  font-weight: 500
  font-size: 18px
  line-height: 32px
  background: $brand-green-color
  color: #FFFFFF
  cursor: pointer
  user-select: none
  transition: all .27s ease
  &_signUpDone
    margin-top: 32px
    min-height: 50px
  &_authForm
    margin-top: 25px
    +breakpoint($small)
      margin-top: 16px
  &:disabled
    background: $neutral-light-gray-color
    pointer-events: none
  &:not(:disabled)
    &:hover
      background: $brand-green-hover-color

.focusButton
  border: 1px solid $brand-green-color
  box-shadow: 0px 0px 0px 4px $box-shadow-green-color

.forgotPassword
  font-size: 14px
  line-height: 18px
  text-decoration-line: underline
  color: $neutral-deep-gray-color
  a
    color: $neutral-deep-gray-color
    &:hover
      color: $brand-green-hover-color
  &_loginForm
    margin-top: 5px

.formDescText
  font-weight: 400
  font-size: 16px
  line-height: 24px
  &_gray
    color: $neutral-dark-gray-color
  &_signUpForm
    margin-top: 70px
  &_signUpForm_beforeButton
    font-size: 14px
    margin-top: 8px
    line-height: 18px
    +breakpoint($small)
      font-size: 12px
  &_signUpDoneUp
    margin-top: 24px
    color: $neutral-dark-gray-color
    text-align: center
  &_signUpDoneBottom
    color: $neutral-dark-gray-color
    text-align: center
    margin-top: 8px
    font-size: 14px
    line-height: 22px
  &_loginForm,
  &_checkEmailForm,
  &_checkEmailUpForm,
  &_restoreForm,
  &_restoreFormUp
    margin-top: 25px
    text-align: center
    +breakpoint($small)
      margin-top: 16px

.errorMessage
  color: $border-status-danger-color

// Зеленая плашка про шифрование в мождалке подключения интеграций
.connectProtectionAlert
  padding: 16px 24px
  background: $status-success-500-color
  border-radius: 16px
  font-weight: 400
  font-size: 16px
  line-height: 24px
  color: $status-success-900-color
  display: flex
  &__iconWrap
    width: 24px
    height: 24px
  &__textWrap
    margin-left: 16px

// Поле ввода поискового запроса в селекторе
.searchBlock
  border: 1px solid $border-neutral-light-gray-color
  background: #FFF
  border-radius: 8px
  height: 40px
  display: flex
  padding: 0 12px 0 10px
  align-items: center
  position: relative
  width: 100%
  &:hover
    .searchIconWrapper
      svg path
        fill: $black-color
  &_withIcon
    .searchIconWrapper
      order: 0
      width: 24px
      height: 24px
      display: block
      display: flex
      justify-content: center
      align-items: center
      margin-right: 10px
      svg path
        transition: all .27s ease
        fill: $neutral-dark-gray-color
  &_withLoader input
    padding-right: 20px
  &_withClearButton input
    padding-right: 20px
  &_withClearButtonAndLoader input
    padding-right: 50px
  input
    order: 1
    font-size: 14px
    border: none
    width: 100%
    @media (min-width: 768px)
      font-size: 14px
    &::placeholder
      color: $neutral-dark-gray-color
    &:focus + .searchIconWrapper
      svg path
        fill: $black-color
    &:hover,
    &:focus,
    &:active
      outline: none
      border: 0
      border-radius: 0
      box-shadow: none

.resetSearch
  composes: button
  display: block
  width: 20px
  height: 20px
  border-radius: 100%
  background: none
  position: absolute
  top: 10px
  right: 10px
  z-index: -1
  opacity: 0
  pointer-events: none
  border: 1px solid $border-neutral-light-gray-color
  transition: all .27s ease
  &::after
    content: ''
    display: block
    width: 8px
    height: 8px
    background-color: transparent
    background-image: url('~@Icons/close.svg')
    background-size: contain
    background-repeat: no-repeat
    background-position: 50% 50%
    opacity: 0.4
    position: absolute
    top: 5px
    left: 0
    right: 0
    margin: 0 auto
    z-index: 1
    transition: all .27s ease
  &:hover
    border-radius: 100%
    border: 1px solid $neutral-dark-gray-color
    &::after
      opacity: 1
  &_visible
    composes: resetSearch
    opacity: 1
    z-index: 1
    cursor: pointer
    pointer-events: unset

// UploadFileInput component
.uploadFileInputWrapper
  width: 100%
  min-height: 132px
  display: flex
  justify-content: center
  flex-direction: column
  align-items: center
  border: 1px dashed $neutral-light-gray-color
  border-radius: 8px
  transition: all .27s ease
  position: relative
  &:hover
    background: $brand-green-hover-background-color
    border-color: $brand-green-color
    .uploadFileInput__label
      color: $brand-green-color
  &_fileError,
  &_fileLoading,
  &_fileLoaded
    background: #FFF
    border: 1px solid $neutral-light-gray-color
    &:hover
      background: #FFF !important
      border-color: $neutral-light-gray-color !important
  input
    position: absolute
    left: 0
    top: 0
    height: 100%
    width: 100%
    cursor: pointer
    opacity: 0

.uploadFileInput
  &__label
    display: flex
    flex-direction: column
    align-items: center
  &__icon
    width: 24px
    height: 24px
  &__fileName,
  &__title
    display: block
    margin-top: 8px
    font-size: 14px
    line-height: 18px
    font-weight: 500
    color: $neutral-deep-gray-color
    a
      color: $neutral-deep-gray-color
      text-decoration: underline
  &__description
    margin-top: 8px
    font-weight: 400
    font-size: 12px
    line-height: 16px
    color: $neutral-dark-gray-color
  &__buttonsWrap
    display: flex
  &__button
    composes: button
    background: none
    text-decoration: underline
    color: $neutral-deep-gray-color
    font-weight: 400
    font-size: 16px
    line-height: 24px
    cursor: pointer
    &:hover
      color: $brand-green-hover-color

// Skip button
.skipButton
  composes: button
  background: none
  font-weight: 400
  font-size: 16px
  line-height: 24px
  color: $neutral-dark-gray-color
  cursor: pointer
  &:hover
    color: $brand-green-hover-color

// Preview
.devicePreview
  background-image: url('~@Icons/device.svg')
  width: 460px
  height: 825px
  background-position: center top
  background-repeat: no-repeat
  padding: 69px
  position: relative
  &__greenCloudFly
    height: 131px
    width: 176px
    position: absolute
    right: -82px
    top: 75px
    +breakpoint($extra-large)
      right: -78px
    div
      position: relative
    svg
      position: absolute
      top: 0
      left: 0
    span
      padding-top: 28px
      font-weight: 500
      font-size: 16px
      line-height: 24px
      text-align: center
      color: #FFFFFF
      position: relative
      display: block
      width: 100%
      height: 100%
  &__inner
    padding: 24px 16px
  &__companyName
    font-weight: 400
    font-size: 14px
    line-height: 20px
    text-align: center
    color: $neutral-deep-gray-color
  &__address
    font-weight: 500
    font-size: 16px
    line-height: 24px
    color: $neutral-deep-gray-color
    text-align: center
  &__companyInfo
    margin-top: 40px
    border: 1px solid $border-neutral-light-gray-color
    border-radius: 16px
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    padding: 24px
    text-align: center
  &__thanksWrap
    margin-top: 80px
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    text-align: center
  &__partyIcon
    height: 32px
    svg
      width: 32px
      height: 32px
  &__title
    font-weight: 600
    font-size: 30px
    line-height: 40px
    &_choice
      max-width: 250px
  &__logoWrap
    margin-top: 20px
    width: 64px
    height: 64px
    background: $border-neutral-light-gray-color
    border-radius: 75px
    display: flex
    align-items: center
    justify-content: center
  &__firstChartLogo
    font-weight: 600
    font-size: 30px
    line-height: 40px
    color: $neutral-dark-gray-color
    text-transform: uppercase
  &__logo
    padding: 0
    width: 64px
    height: 64px
    border-radius: 75px
    img
      object-fit: cover
      border-radius: 75px
      width: 100%
      height: 100%
  &__logoCompanyName
    margin-top: 8px
    font-weight: 500
    font-size: 16px
    line-height: 24px
    text-align: center
    color: $neutral-dark-gray-color
  &__buttonsWrap
    margin-top: 16px
    display: grid
    grid-template-columns: 1fr 1fr
    grid-column-gap: 16px
    svg
      width: 64px
      height: 64px
  &__smileButton
    height: 114px
    border-radius: 12px
    &_positive
      background-color: $activator-positive-color
    &_negative
      background-color: $activator-negative-color
  &__subTitle
    margin-top: 16px
    color: $neutral-deep-gray-color
    font-weight: 400
    font-size: 16px
    line-height: 24px
    text-align: center
  &__platformEmpty
    margin-top: 24px
    border: 2px dashed $border-neutral-light-gray-color
    border-radius: 12px
    padding: 12px
    font-weight: 500
    font-size: 14px
    line-height: 20px
    color: $neutral-deep-gray-color
    text-align: center
  &__platformsWrap
    margin-top: 24px

.footerPreviewText
  color: $neutral-deep-gray-color
  font-weight: 400
  font-size: 16px
  line-height: 24px
  margin-top: -60px
  text-align: center
  margin-bottom: 15px

.activatorPreviewPlatform
  height: 48px
  background: $neutral-disabled-color
  border-radius: 12px
  display: flex
  justify-content: center
  align-items: center
  margin-top: 10px
  &__name
    display: block
    margin-left: 10px
    font-weight: 500
    font-size: 16px
    line-height: 24px

// Dashboard layout
.containerHasSidebar
  display: flex
  flex-direction: row
  padding-left: 264px
  +breakpoint($small)
    padding-left: 0

.sidebar
  position: fixed
  height: 100%
  flex: 0 0 264px
  width: 264px
  top: 0
  left: 0
  display: flex
  flex-direction: column
  background-color: white
  +breakpoint($small)
    left: 0
    overflow-y: auto
    -webkit-overflow-scrolling: touch
    position: fixed
    width: 300px
    &_isOpen
      transform: translateX(-100%) scaleX(.8)
      transition: transform .2s linear, z-index 0s linear .4s
    &_isClose
      transform: translateX(0) scaleX(1)
      transition: transform .3s ease

.scrim
  display: none
  +breakpoint($small)
    background-color: $modal-overlay-background-color
    bottom: 0
    display: block
    height: 100%
    left: 0
    position: fixed
    right: 0
    width: 100%
    &.isOpen
      opacity: 0
      transition: opacity .4s ease, z-index 0s linear .6s
      pointer-events: none
    &.isClose
      opacity: 1
      transition: opacity .6s ease

.sidenavHeader

.sidebarSeparate
  height: 2px
  width: calc(100% - 16px)
  background: $neutral-disabled-color
  margin-left: 8px
  margin-right: 8px

.sidenav
  display: flex
  flex-grow: 1

.footerSidebar
  display: flex
  flex-direction: column

.sidenavBody
  width: 100%

.contentContainer
  flex: auto
  padding: 24px 48px
  display: flex
  flex-direction: column
  +breakpoint($extra-large)
    padding: 18px 24px
  +breakpoint($small)
    padding: 0

.header
  +breakpoint($small)
    padding: 12px 16px 12px 20px
    background: $neutral-deep-gray-color
    width: 100%
  &__inner
    +breakpoint($small)
      display: flex
      justify-content: center
      align-items: center
  &__burgerButton
    display: none
    button
      display: flex
      justify-content: center
      align-items: center
      background: none
    +breakpoint($small)
      display: block
  &__profile
    display: none
    border-radius: 100px
    overflow: hidden
    width: 48px
    height: 48px
    +breakpoint($small)
      display: block
    &__logo
      padding: 0
      &_firstChartLogo
        width: 100%
        height: 100%
        font-style: normal
        font-weight: 600
        font-size: 20px
        line-height: 30px
        background: $border-neutral-light-gray-color
        color: $neutral-dark-gray-color
        display: flex
        align-items: center
        justify-content: center
        text-transform: uppercase
  &__title
    font-weight: 600
    font-size: 32px
    line-height: 42px
    display: flex
    padding: 0
    margin: 0
    display: flex
    flex-grow: 1
    &_desktopHide
      display: none
      +breakpoint($small)
        display: flex !important
    +breakpoint($small)
      font-weight: 600
      font-size: 24px
      line-height: 32px
      color: #FFFFFF
      margin-left: 20px

.content
  display: flex
  flex-wrap: wrap
  flex: 1
  +breakpoint($small)
    padding: 24px 16px

.dashboardContentBlock
  padding: 28px 24px
  background: #FFFFFF
  border: 1px solid $border-neutral-light-gray-color
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)
  border-radius: 16px
  &__titleStyle
    font-weight: 500
    font-size: 22px
    line-height: 28px
    padding: 0
    margin: 0
  &__descriptionGrayStyle
    margin-top: 12px
    font-weight: 400
    font-size: 16px
    line-height: 24px
    color: $neutral-deep-gray-color
    max-width: 680px
  &__linkBlackStyle
    font-weight: 500
    font-size: 16px
    line-height: 24px
    color: $black-color
    cursor: pointer
    transition: all .27s ease
    &:hover
      text-decoration: underline
      color: $black-color
  &__selectStyle
    height: 48px
    width: 100px
  &__titleAndButtonWrap
    display: flex
    justify-content: space-between

// ToastContainer
.toastContainer
  +breakpoint($small)
    max-width: calc(100vw - (16px * 2))
    left: 1em !important
    bottom: 1em !important

.loaderMiddle
  display: flex
  justify-content: center
  padding: 16px
  .mtom
    margin-top: 15
  .mbottom
    margin-bottom: 15

.loadingOpacity
  pointer-events: none
  & *
    pointer-events: none
  &::after
    content: ''
    display: block
    width: 100%
    height: 100%
    background-color: rgba(255,255,255,0.5)
    position: absolute
    top: 0
    left: 0
    z-index: 1
    pointer-events: none
