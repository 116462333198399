@import '@Styles/laika-variables.module.sass'
@import '@Styles/utils.sass'

.laikaFooterLogo
  margin-left: 24px
  margin-bottom: 14px
  svg
    width: 78px
    height: auto
  svg path
    fill: $neutral-dark-gray-color