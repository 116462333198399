@import '@Styles/laika-variables.module.sass'
@import '@Styles/utils.sass'

.button
  appearance: none
  outline: none
  border: 0
  border-radius: 0
  box-shadow: none
  &:hover,
  &:focus,
  &:active
    outline: none
    border: 0
    border-radius: 0
    box-shadow: none

.buttonStyle
  composes: button
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  cursor: pointer
  user-select: none
  transition: all .27s ease
  border-radius: 12px
  &:hover,
  &:focus,
  &:active
    border-radius: 12px

.buttonGreenStyle
  background: $brand-green-color
  color: #FFFFFF
  &:disabled
    background: $neutral-light-gray-color
  &:not(:disabled)
    &:hover
      background: $brand-green-hover-color

.bigButton
  composes: buttonStyle
  composes: buttonGreenStyle
  min-height: 64px
  font-weight: 500
  font-size: 18px
  line-height: 32px

// Icon Button component
.iconButton
  composes: buttonStyle
  composes: buttonGreenStyle
  min-height: 48px
  font-weight: 700
  font-size: 12px
  line-height: 24px
  letter-spacing: 1px
  text-transform: uppercase
  padding-left: 12px
  padding-right: 12px
  border-radius: 8px
  &:hover,
  &:focus,
  &:active
    border-radius: 8px

// Text button component
.textButton
  font-weight: 500
  font-size: 16px
  line-height: 24px
  color: $neutral-deep-gray-color
  cursor: pointer
  transition: all .27s ease
  &:hover
    color: $brand-green-color

// Button component
.simpleButton
  background: $neutral-disabled-color
  cursor: pointer
  padding: 6px 12px 6px 12px
  min-width: 125px
  border-radius: 8px
  transition: all .27s ease
  &__name
    font-weight: 700
    font-size: 12px
    line-height: 24px
    text-align: center
    letter-spacing: 1px
    text-transform: uppercase
    color: $neutral-deep-gray-color
    transition: all .27s ease
  &_disabled
    pointer-events: none
    background: $border-neutral-light-gray-color
    .simpleButton__name
      color: $progress-bar-light-gray-color
  &:hover
    background: $brand-green-hover-background-color
    border-radius: 8px
    .simpleButton__name
      color: $brand-green-color

// ToStepLinkButton component
.toStepLinkButton
  display: flex
  border: 1px solid $border-neutral-light-gray-color
  border-radius: 12px
  padding: 16px 12px
  transition: all .27s ease
  justify-content: space-between
  svg path
    transition: all .27s ease
  &:hover
    background: $neutral-disabled-color
    text-decoration: none
    svg path
      fill: $neutral-deep-gray-color
  &_file
    padding: 0
    position: relative
  label
    padding: 16px 12px
    display: flex
    justify-content: space-between
    width: 100%
  &__wrapInfo
    display: flex
    flex-direction: column
    padding-right: 5px
  &__title
    font-weight: 500
    font-size: 16px
    line-height: 24px
    color: $black-color
    text-decoration: none
  &__desc
    font-weight: 400
    font-size: 14px
    line-height: 20px
    color: $neutral-deep-gray-color
    text-decoration: none
  &__iconWrap
    display: flex
    align-items: center
    justify-content: center
  input
    position: absolute
    left: 0
    top: 0
    height: 100%
    width: 100%
    cursor: pointer
    opacity: 0

// TripleDotButton
.tripleDotButton
  composes: buttonStyle
  background: none
  &:hover
    svg path
      transition: all .27s ease
    svg path
      fill: $brand-green-hover-color

// Filter
.filterResetButton
  font-weight: 700
  font-size: 12px
  line-height: 24px
  letter-spacing: 1px
  text-transform: uppercase
  user-select: none
