// Breakpoints - используется в связки с миксином +breakpoint
$extra-small: 575
$small: 767
$medium: 991
$large: 1199
$extra-large: 1399

// Color Variables
$black-color: #202526
$white-color: #FFFFFF
$dark-green-color: #0B323B
$neutral-disabled-color: #F3F7FA
$brand-green-color: #17C788
$brand-green-hover-color: #0CAC73
$brand-green-hover-background-color: #E3F8F1
$neutral-light-gray-color: #D2D9DF
$border-neutral-light-gray-color: #E2E9EE
$progress-bar-light-gray-color: #BDC9D3
$neutral-deep-gray-color: #5C6A75
$neutral-dark-gray-color: #8A99A4
$border-status-danger-color: #E65050
$border-status-danger-hover-color: #C73232
$box-shadow-green-color: rgba(23, 199, 135, 0.25)
$box-shadow-gray-color: rgba(4, 9, 33, 0.08)
$box-shadow-neutral-gray-color: rgba(92, 106, 117, 0.06)
$activator-positive-color: #FBF4D7
$activator-negative-color: #FAE9ED
$modal-box-shadow-color: rgba(4, 9, 33, 0.1)
$modal-overlay-background-color: rgba(92, 106, 117, 0.35)
$status-success-500-color: #E9F8EE
$status-success-900-color: #22BB59
$status-warning-500-color:#FFF6EF
$status-warning-900-color:#F78A2B
$status-danger-500-color:#FFF0F0
$status-danger-900-color:#E55050
$status-info-900-color:#3C91EF
$status-info-500-color:#F3F8FE
$status-info-900-hover-color: #0A5FBD

$cover-yellow: #FAF2D6
