@import '@Styles/laika-variables.module.sass'
@import '@Styles/utils.sass'

.container
  width: 100%
  background-color: $neutral-disabled-color
  min-height: 100vh
  overflow: hidden
  display: flex

.container *
  font-family: 'IBM Plex Sans', sans-serif

.headerContainer
  display: flex
  justify-content: space-between
  padding-bottom: 18px
  +breakpoint($small)
    flex-direction: column

.headerTabsContainer
  padding: 0
  +breakpoint($small)
    padding: 24px 16px 0

.requestButtonWrapper
  display: flex
  justify-content: center
  align-items: center
  +breakpoint($small)
    padding: 24px 16px
    padding: 24px 16px 0 16px
