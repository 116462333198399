@import '@Styles/laika-variables.module.sass'
@import '@Styles/utils.sass'

.profileDropdown
  padding: 0
  &__separate
    background: $neutral-disabled-color
    height: 2px
    width: 100%
    margin: 4px 0 4px

.profileDropdownItem
  display: flex
  justify-content: flex-start
  width: 100%
  padding: 12px 32px 12px 12px
  border-radius: 8px
  position: relative
  cursor: pointer
  &:hover
    background: $neutral-disabled-color
    .profileDropdownItem__label
      color: $black-color
    svg path
      fill: $black-color
  &_disabled
    pointer-events: none
    .selectOption__label
      color: $progress-bar-light-gray-color
    svg path
      fill: $progress-bar-light-gray-color
  &_selected
    background: $neutral-disabled-color
    .selectOption__label
      font-weight: 600
  &__iconWrap
    display: flex
    justify-content: center
    align-items: center
  &__label
    display: inline-block
    width: auto
    font-weight: 500
    font-size: 16px
    line-height: 24px
    color: $neutral-deep-gray-color
    margin-left: 12px