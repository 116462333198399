@font-face
  font-family: 'Onest'
  src: url('../fonts/OnestRegular.woff') format('woff')
  font-weight: 400
  font-style: normal
  font-display: swap

@font-face
  font-family: 'Onest'
  src: url('../fonts/OnestMedium.woff') format('woff')
  font-weight: 500
  font-style: normal
  font-display: swap

@font-face
  font-family: 'Onest'
  src: url('../fonts/OnestBold.woff') format('woff')
  font-weight: 700
  font-style: normal
  font-display: swap
