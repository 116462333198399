@import '@Styles/laika-variables.module.sass'
@import '@Styles/utils.sass'

.container
  width: 100%
  background-color: $neutral-disabled-color
  min-height: 100vh
  overflow: hidden

.container *
  font-family: 'Onest', sans-serif

.middle
  left: 50%
  position: absolute
  text-align: center
  top: 50%
  transform: translateX(-50%) translateY(-50%)

.contentWrapper
  min-height: 100vh
  overflow: hidden
  display: flex
  justify-content: center
  align-items: center
  +breakpoint($small)
    padding: 16px

.content
  padding: 0
  +breakpoint($small)
    width: 100%

.header
  display: flex
  justify-content: center
  .logo
    transition: opacity .2s ease
    &:hover
      opacity: .7
      img
        width: 134px

.form
  margin-top: 35px
