@import '@Styles/laika-variables.module.sass'
@import '@Styles/utils.sass'

.selectOption
  display: flex
  justify-content: flex-start
  gap: 12px
  width: 100%
  margin: 0 0 8px 0
  padding: 8px 32px 7px 12px
  border-radius: 8px
  position: relative
  cursor: pointer
  &:last-child
    border-bottom: 0
  &:hover
    background: $neutral-disabled-color
  &_disabled
    pointer-events: none
    .selectOption__label
      color: $progress-bar-light-gray-color
    svg path
      fill: $progress-bar-light-gray-color
  &_selected
    background: $neutral-disabled-color
    .selectOption__label
      font-weight: 600
  &__label
    display: inline-block
    width: auto
    font-weight: 500
    font-size: 16px
    line-height: 24px
    color: $neutral-deep-gray-color
