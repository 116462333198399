@import '@Styles/laika-variables.module.sass'
@import '@Styles/utils.sass'

.button
  appearance: none
  outline: none
  border: 0
  border-radius: 0
  box-shadow: none
  &:hover,
  &:focus,
  &:active
    outline: none
    border: 0
    border-radius: 0
    box-shadow: none

.lkNotificationsContainer
  display: block
  width: 100%
  max-width: 436px
  background-color: transparent !important
  margin: 0
  padding: 0

.lkNotificationWrapper
  // display: block
  // max-width: 436px
  margin: 12px 0 0
  padding: 12px 16px
  border-radius: 12px !important
  box-shadow: 0px 6px 12px rgba(4, 9, 33, 0.08)
  position: relative

.lkNotificationsContainerDefault
  background-color: $black-color !important

.lkNotificationsContainerError
  background-color: $border-status-danger-color !important

.lkNotificationsContainerSuccess
  background-color: $brand-green-color !important

.lkNotificationsContainerWarning
  background-color: $status-warning-900-color !important

.lkNotificationsContainerInfo
  background-color: $status-info-900-color !important

.lkNotification
  // display: block
  // max-width: 380px
  // background-color: transparent

.lkNotification__title
  display: block
  margin: 0 0 4px
  font-size: 12px
  font-weight: 600
  line-height: 20px
  color: #ffffff
  text-align: left
  text-transform: uppercase
  letter-spacing: 1px
  &_withCounter
    display: flex
    align-items: center

.lkNotification__counter
  background: rgba(210, 217, 223, 0.24)
  color: rgba(255, 255, 255, 0.6)
  border: 1px solid rgba(0, 0, 0, 0.12)
  border-radius: 12px
  margin-left: 7px
  justify-content: center
  display: flex
  align-items: center
  min-width: 28px

.lkNotification__markAllReadButton
  composes: button
  background: #FFF
  color: $neutral-deep-gray-color
  border-radius: 8px
  justify-content: center
  display: flex
  align-items: center
  font-weight: bold
  font-size: 12px
  line-height: 16px
  letter-spacing: 1px
  text-transform: uppercase
  padding: 8px 9px
  margin-top: 11px
  cursor: pointer
  transition: all .27s ease
  &:hover
    background: #BDC9D3
    border-radius: 8px

.lkNotification__text
  display: block
  margin: 0
  font-size: 14px
  font-weight: 400
  line-height: 20px
  color: #ffffff
  text-align: left

// Стиль для ссылок, стилизованных под кнопку, в тексте тостера находится в src/styles/custom.css
.lkNotificationClsBtn
  appearance: none
  width: 20px
  height: 20px
  margin: 0
  padding: 0
  background-color: #ffffff
  border: 0
  border-radius: 50%
  box-shadow: none
  outline: none
  cursor: pointer
  position: absolute
  top: 8px
  right: 8px
  z-index: 1
  transition: all .27s ease
  &:hover,
  &:focus,
  &:active
    border: 0
    border-radius: 50%
    box-shadow: none
    outline: none
    background-color: #ffffff
    opacity: 0.5
  & svg
    width: 10px
    height: 10px
