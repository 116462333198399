@import '@Styles/laika-variables.module.sass'
@import '@Styles/utils.sass'

// SimpleTabs
.blockTabs
  padding: 0
  display: flex

.blockTab
  min-height: 32px
  background: none
  border-radius: 8px
  padding: 4px 8px
  display: flex
  align-items: center
  font-size: 16px
  line-height: 24px
  color: $neutral-dark-gray-color
  font-weight: 500
  cursor: pointer
  transition: all .27s ease
  &:not(:first-child)
    margin-left: 16px
  &:hover
    background: $neutral-disabled-color
  &_active
    color: $black-color
    background: $neutral-disabled-color
  &__count
    margin-left: 8px
    color: $status-warning-900-color
    &_green
      color: $status-success-900-color

// HeaderTabs

.headTabs
  padding: 0
  display: flex

.headTab
  min-height: 48px
  background: none
  border-radius: 12px
  padding: 12px 16px
  display: flex
  align-items: center
  font-weight: 500
  font-size: 16px
  line-height: 24px
  color: $neutral-dark-gray-color
  cursor: pointer
  transition: all .27s ease
  &:not(:first-child)
    margin-left: 24px
  &:hover
    color: $neutral-dark-gray-color
    background: $border-neutral-light-gray-color
  &_active
    color: $black-color
    background: $border-neutral-light-gray-color