@import '@Styles/laika-variables.module.sass'
@import '@Styles/utils.sass'

.sideNav
  padding-top: 8px
  padding-bottom: 8px
  padding-left: 8px
  padding-right: 8px

.sideNavItem
  padding: 12px 10px
  border-radius: 8px
  display: flex
  color: $neutral-deep-gray-color !important
  align-items: center
  cursor: pointer
  font-size: 16px
  line-height: 24px
  width: 100%
  font-weight: 500
  &_active,
  &:hover
    color: $black-color !important
    background: $neutral-disabled-color
    svg path
      fill: $black-color
  &:not(:last-child)
    margin-bottom: 8px
  &__icon
    width: 20px
    height: 20px
    svg path
      fill: $neutral-deep-gray-color
      transition: all .27s ease
  &__title
    margin-left: 14px











.header
  background-color: #fff
  position: relative

.menu
  display: flex
  border-bottom: 1px solid #E2E9EE
  height: 56px
  position: relative
  z-index: 2
  background-color: #fff
  +breakpoint(768)
    height: 48px
    z-index: 1000

.network
  display: flex
  flex-shrink: 0
  flex-basis: 200px
  position: relative
  & > div
    flex: 1
  +breakpoint(768)
    flex: 1
  .head
    border-left: 1px solid #e0e9e5
    border-right: 1px solid #e0e9e5
    height: 100%
    padding: 10px 15px
    +breakpoint(768)
      padding: 5px 10px
    &.isOpen
      border-color: transparent
      box-shadow: 0 12px 24px 0 rgba(177, 199, 188, .4)
    .title
      color: #87938f
      font-size: 12px
      font-weight: 500
      letter-spacing: 1px
      text-transform: uppercase
    .name
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      max-width: 200px
      +breakpoint(768)
        // screenWidth - logoBlockWidth - networkBlockPadding - networkNamePadding - burgerBlockWidth - avatarBlockWidth
        max-width: calc(100vw - 48px - 20px - 24px - 48px - 48px)
  .list
    background-color: #fff
    border-bottom-left-radius: 8px
    border-bottom-right-radius: 8px
    display: none
    max-height: 60vh
    overflow: auto
    position: absolute
    width: 100%
    z-index: 10000
    &.isOpen
      box-shadow: 0 12px 24px 0 rgba(177, 199, 188, .4)
      display: block
    .item
      color: inherit
      display: block
      padding: 15px
      text-decoration: none
      &:hover,
      &.isActive
        color: #7d1de4

.profile
  border-left: 1px solid #e0e9e5
  display: flex
  flex-shrink: 0
  position: relative
  background: #FFF
  .head
    align-items: center
    display: flex
    height: 100%
    padding: 0 15px
    min-width: 190px
    +breakpoint(900)
      justify-content: center
      padding: 5px
      width: 50px
      min-width: auto
      &::after
        display: none
    .pic
      border-radius: 100%
      height: 36px
      margin-right: 15px
      object-fit: cover
      +breakpoint(900)
        margin-right: 0
    .name
      font-weight: 500
      max-width: 300px
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      padding-right: 20px
      +breakpoint(900)
        display: none
      .position
        color: #8a938e
        font-size: 12px
        font-weight: 400
  .list
    background-color: #fff
    border-radius: 8px
    display: none
    position: absolute
    right: 20px
    top: 100%
    transform: translateY(10px)
    width: 160px
    z-index: 10000
    &.isOpen
      box-shadow: 0 12px 24px 0 rgba(177, 199, 188, .4)
      display: block
    .logoutSep
      background-color: #e0e9e5
      border: 0
      height: 1px
    .item
      color: inherit
      cursor: pointer
      display: block
      padding: 10px 10px 10px 55px
      position: relative
      text-decoration: none
      &:hover,
      &:focus,
      &.isActive
        color: #7d1de4
        .icon
          *
            fill: #7d1de4
      .icon
        font-size: 0
        left: 25px
        position: absolute
        top: 50%
        transform: translateY(-50%)
        *
          fill: #0e0e0e
        svg
          width: 18px
