
.tippy-box[data-theme~="custom"] {
  background-color: #f7f7f7;
  color: black;
  border: 1px solid #ededed;
  border-radius: 0;
}

/* Toastify override css variables */
/* https://fkhadra.github.io/react-toastify/how-to-style/#override-css-variables */
:root {
  --toastify-toast-width: 416px;
  --toastify-toast-min-height: 56px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 99999;
}