@import '@Styles/laika-variables.module.sass'
@import '@Styles/utils.sass'

// Commons
.label
  font-style: normal
  font-weight: 500
  font-size: 14px
  line-height: 18px
  margin-bottom: 6px

.inputBigCommonStyle
  font-style: normal
  font-weight: 400
  font-size: 18px
  line-height: 32px
  padding: 12px 16px
  background: #FFFFFF
  border: 1px solid $neutral-light-gray-color
  border-radius: 12px

.inputMidCommonStyle
  font-style: normal
  font-weight: 400
  font-size: 16px
  line-height: 24px
  padding: 12px 16px
  background: #FFFFFF
  border: 1px solid $neutral-light-gray-color
  border-radius: 12px

// Components
.signUpInputGroup
  margin-top: 24px
  display: flex
  flex-direction: column
  +breakpoint($small)
    margin-top: 16px

.signUpSelectInputGroup
  flex-direction: column
  display: flex
  position: relative

.selectWrapper
  flex-direction: column
  display: flex

.signUpSelectInputWrap
  position: relative
  cursor: pointer
  &::after
    content: ''
    display: block
    width: 24px
    height: 24px
    background-image: url('~@Icons/arrow-down-select.svg')
    background-repeat: no-repeat
    background-size: contain
    background-position: 50% 50%
    position: absolute
    top: calc(50% - 10px)
    right: 16px
    transition: all .27s ease
    transform: none
  &_opened::after
    top: calc(50% - 12px)
    transform: rotate(180deg)

.signUpSelectInput
  composes: inputBigCommonStyle
  display: flex
  align-items: center
  outline: none !important
  cursor: pointer
  width: 100%
  &_opened,
  &:focus-visible
    border: 1px solid $brand-green-color
    box-shadow: 0px 0px 0px 4px $box-shadow-green-color
  &_isError
    border: 1px solid $border-status-danger-color
  &:disabled
    background: $neutral-disabled-color
  &_placeholder
    color: $neutral-dark-gray-color

.signUpDropdown
  display: none
  width: 100%
  height: auto
  margin: 0
  padding: 12px 12px 12px 12px
  position: absolute
  top: calc(100% + 5px)
  left: 0
  background-color: #FFF
  border: 1px solid $border-neutral-light-gray-color
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)
  border-radius: 8px
  opacity: 0
  z-index: -1
  overflow: hidden
  &_mobileTop
    +breakpoint($small)
      bottom: calc(100% + 5px)
      top: initial
  &_visible
    display: block
    opacity: 1
    z-index: 3
  &__optionsList
    margin-top: 16px
    &_emptyOptions
      margin: 0
  &__optionsWrap
    overflow: hidden
    padding-right: 12px
    padding-left: 0

.notFoundOptions
  display: block
  color: $neutral-dark-gray-color
  text-align: center

// Стандартный селектор
.selectInputWrap
  position: relative
  cursor: pointer
  &::after
    content: ''
    display: block
    width: 24px
    height: 24px
    background-image: url('~@Icons/arrow-down-select.svg')
    background-repeat: no-repeat
    background-size: contain
    background-position: 50% 50%
    position: absolute
    top: calc(50% - 10px)
    right: 16px
    transition: all .27s ease
    transform: none
  &_opened::after
    top: calc(50% - 12px)
    transform: rotate(180deg)

.selectInput
  composes: inputMidCommonStyle
  display: flex
  align-items: center
  outline: none !important
  width: 100%
  cursor: pointer
  padding-right: 38px
  &_opened,
  &:focus-visible
    border: 1px solid $brand-green-color
    box-shadow: 0px 0px 0px 4px $box-shadow-green-color
  &_isError
    border: 1px solid $border-status-danger-color
  &:disabled
    background: $neutral-disabled-color
  &_placeholder
    color: $neutral-dark-gray-color

.selectDropdown
  display: none
  width: 100%
  height: auto
  margin: 0
  padding: 12px 12px 12px 12px
  position: absolute
  top: calc(100% + 5px)
  left: 0
  background-color: #FFF
  border: 1px solid $border-neutral-light-gray-color
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)
  border-radius: 8px
  opacity: 0
  z-index: -1
  overflow: hidden
  &_mobileTop
    +breakpoint($small)
      bottom: calc(100% + 5px)
      top: initial
  &_visible
    display: block
    opacity: 1
    z-index: 3
  &_slideUp
    bottom: calc(100% + 5px)
    top: initial
  &__optionsList
    &_emptyOptions
      margin: 0
  &__optionsWrap
    overflow: hidden
    padding-right: 12px
    padding-left: 0