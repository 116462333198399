@import '@Styles/laika-variables.module.sass'
@import '@Styles/utils.sass'

.profileBlockWrapper
  position: relative

.profileBlock
  padding: 12px 8px
  display: flex
  &__button
    cursor: pointer
    display: flex
    border-radius: 8px
    padding: 12px 8px
    width: 100%
    transition: all .27s ease
    &_isOpen,
    &:hover
      background-color: $neutral-disabled-color
  &__logo
    border-radius: 100px
    overflow: hidden
    width: 48px
    height: 48px
    flex-shrink: 0
    position: relative
    cursor: pointer
    &__filter
      left: 0
      top: 0
      height: 100%
      width: 100%
      position: absolute
      opacity: 0
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))
      filter: blur(1.5px)
      transition: all .27s ease
    &__uploadIconWrapper
      left: 0
      top: 0
      height: 100%
      width: 100%
      position: absolute
      opacity: 0
      transition: all .27s ease
      svg
        height: 16px
        width: 16px
        margin-left: calc(100% / 2 - 8px)
        margin-top: calc(100% / 2 - 8px)
      svg path
        fill: #FFFFFF
    input
      position: absolute
      left: 0
      top: 0
      height: 100%
      width: 100%
      cursor: pointer
      opacity: 0
    &:hover
      .profileBlock__logo__filter,
      .profileBlock__logo__uploadIconWrapper
        opacity: 1
      img
        filter: blur(1.5px)
    img
      height: 100%
      width: 100%
      object-fit: cover
    &_firstChartLogo
      font-style: normal
      font-weight: 600
      font-size: 20px
      line-height: 30px
      background: $border-neutral-light-gray-color
      color: $neutral-dark-gray-color
      display: flex
      align-items: center
      justify-content: center
      text-transform: uppercase
  &__infoWrapper
    display: flex
    flex-direction: column
    margin-left: 10px
    flex-grow: 1
  &__name
    font-weight: 500
    font-size: 16px
    line-height: 24px
    color: $neutral-deep-gray-color
  &__tariff
    margin-top: 8px
    font-weight: 500
    font-size: 12px
    line-height: 16px
    color: $neutral-dark-gray-color
    user-select: none
  &__buttonWrap
    display: flex
    justify-content: center
    align-items: center
  &__buttonIcon
    width: 24px
    height: 24px
    display: flex
    justify-content: center
    align-items: center
    background: none

.profileDropdownWrapper
  display: none
  width: 100%
  padding-left: 8px
  padding-right: 8px
  opacity: 0
  z-index: -1
  overflow: hidden
  position: absolute
  top: calc(100% - 8px)
  left: 0
  &_visible
    display: block
    opacity: 1
    z-index: 3

.profileDropdown
  width: 100%
  height: auto
  margin: 0
  padding: 4px
  background: #FFFFFF
  border: 1px solid $border-neutral-light-gray-color
  box-shadow: 0px 6px 12px $box-shadow-gray-color
  border-radius: 8px
  &_mobileTop
    +breakpoint($small)
      bottom: calc(100% + 5px)
      top: initial
  &_slideUp
    bottom: calc(100% + 5px)
    top: initial
  &__optionsList
    &_emptyOptions
      margin: 0
  &__optionsWrap
    overflow: hidden
    padding-right: 12px
    padding-left: 0
