@import '@Styles/laika-variables.module.sass'
@import '@Styles/utils.sass'

.errorPageMessage
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  width: 100vw
  min-width: 361px
  height: 100vh
  padding: 0 16px
  gap: 24px
  margin: 0
  font-size: 20px
  line-height: 24px
  text-align: center
  +breakpoint($medium)
    justify-content: flex-start
    padding: 100px 16px
  &__containerImage
    display: flex
    justify-content: center
    align-items: center
    background-color: $status-warning-500-color
    border-radius: 50%
    width: 72px
    min-height: 72px
  &__title
    margin: 0
    font-weight: 700
    font-size: 60px
    line-height: 72px
    color: $dark-green-color
  &__descriptionText
    max-width: 588px
    font-weight: 500
    font-size: 18px
    line-height: 26px
    color: $neutral-deep-gray-color
  &__buttonWrapper
    width: 201px
    margin-top: 16px
    button
      width: 100%
      min-height: 58px
  &__link
    font-weight: 400
    font-size: 16px
    line-height: 24px
  &__404
    min-width: 358px
  &__500
    padding: 200px 0 40px
    min-width: 358px

