@import '@Styles/laika-variables.module.sass'

body
  background-color: #fff
  color: #202526
  font-size: 14px
  font-smoothing: antialiased
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-rendering: optimizeLegibility
  font-feature-settings: 'liga', 'kern'
  -webkit-font-feature-settings: 'liga', 'kern'
  -moz-font-feature-settings: 'liga', 'kern'
  line-height: 1.43
  margin: 0
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif

html
  box-sizing: border-box

*, *:before,
*:after
  box-sizing: inherit

img
  max-width: 100%

a
  color: $brand-green-color
  text-decoration: none
  transition: color .1s ease
  &:hover
    color: $brand-green-hover-color
    text-decoration: none

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

button
  appearance: none
  outline: none
  border: 0
  border-radius: 0
  box-shadow: none
  &:hover,
  &:focus,
  &:active
    outline: none
    border: 0
    box-shadow: none
